import { Card } from '../../Components/Card/Card.tsx';
import { Header } from '../../../Shared/Components/Header/Header.tsx';
import { usePost } from '../../Hooks/usePost/usePost.ts';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../../../Shared/Components/Loading/Loading.tsx';
import { FAB } from '../../../Shared/Components/FAB/FAB.tsx';
import { faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { path } from '../../../Shared/Services/Path/Path.ts';
import { useDeletePost } from '../../Hooks/useDeletePost/useDeletePost.ts';
import { useCurrentUser } from '../../../Shared/Hooks/useCurrentUser/useCurrentUser.ts';

export function Show() {
  const { key } = useParams();
  const { record: post, query } = usePost(key!);
  const deletePost = useDeletePost();
  const navigate = useNavigate();
  const { record: currentUser } = useCurrentUser();
  const isAdmin = currentUser?.admin;

  const handleClick = async () => {
    await deletePost.mutateAsync(key!);
    navigate(path.timeline.list);
  };

  if (query.isLoading || deletePost.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header goBack goBackPath={path.timeline.list} />
      <Card post={post!} expanded />
      {isAdmin && (
        <FAB.Button>
          <FAB.Option icon={faTrashCan} label="Apagar post" onClick={() => void handleClick()} />
          <FAB.Option
            icon={faPenToSquare}
            label="Editar post"
            onClick={() => {
              navigate(generatePath(path.timeline.edit, { key: key }));
            }}
          />
        </FAB.Button>
      )}
    </>
  );
}
