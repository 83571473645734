import { Field } from '../../../Shared/Components/Field/Field';
import Form from '../../../Shared/Components/Form/Form';
import { Section } from '../../../Shared/Components/Section/Section';
import { SubmitBtn } from '../../../Shared/Components/SubmitBtn/SubmitBtn';
import { User } from '../../../Shared/Services/TimefitBackend';
import { ImageInput } from '../../../Timeline/Components/ImageInput/ImageInput';
import * as Yup from 'yup';

interface EditFormProps {
  user: User;
  loading: boolean;
  onSubmit: (values: EditFormValues) => Promise<void>;
}

export interface EditFormValues {
  profilePicture?: string | File;
  name: string;
  description?: string;
}

export function EditForm({ user, loading, onSubmit }: EditFormProps) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
  });

  return (
    <Form
      initialValues={{
        profilePicture: user.profilePicture,
        name: user.firstName ?? '',
        description: user.description,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <ImageInput name="profilePicture" />
      <Section>
        <Field label="Nome" name="name" />
        <Field label="Objetivo" name="description" as="textarea" rows={5} />
      </Section>
      <SubmitBtn loading={loading}>Salvar Alteração</SubmitBtn>
    </Form>
  );
}
