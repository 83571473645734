import { getTimePeriod } from '../../../Shared/Services/Utils/Utils';
import { Time } from '../Time/Time';
import style from './StaffShedule.module.css';
import { DayKey, TimePeriod, TimeSlotAttributes } from '../../../Shared/Services/TimefitBackend';
import { useScheduleState } from '../../Hooks/useScheduleState/useScheduleState.ts';
import { useSelectedDayKey } from '../../Hooks/useSelectedDayKey/useSelectedDayKey.ts';
import { useParams } from 'react-router-dom';

interface Staff {
  name: string;
  proUID: string;
}

interface StaffScheduleProps {
  staff: Staff;
}

const getAvailableTimeSlots = (dayKey: DayKey) => {
  type DayScheduleKey = DayKey | 'everyday';
  type AvailableTimeSlots = Partial<Record<TimePeriod, string[]>>;
  // TODO: Move this to the database
  const staticData: Partial<Record<DayScheduleKey, AvailableTimeSlots>> = {
    everyday: {
      morning: ['07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30'],
      evening: ['12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00'],
      night: ['18:00', '18:30', '19:00', '19:30', '20:00'],
    },
    6: {
      morning: ['08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30'],
      evening: ['12:00'],
    },
  };
  const result = staticData[dayKey] ? staticData[dayKey]! : staticData.everyday!;
  return Object.entries(result) as [TimePeriod, string[]][];
};

export function StaffSchedule({ staff }: StaffScheduleProps) {
  const selectedDayKey = useSelectedDayKey();
  const { unit } = useParams();
  const { isActive } = useScheduleState();

  const getTimeSlot = (timePeriod: TimePeriod, hour: string): TimeSlotAttributes => {
    const timeSlot: TimeSlotAttributes = {
      hour,
      timePeriod,
      ...staff,
      unit: unit!,
    };

    if (isActive(timeSlot)) {
      return isActive(timeSlot)![1];
    }

    return timeSlot;
  };

  const renderSection = () => {
    return getAvailableTimeSlots(selectedDayKey).map(([period, hours]) => {
      return (
        <div key={period} className={style.timeContainer}>
          <span className={style.period}>{getTimePeriod(period)}</span>
          <div className={style.buttonsContainer}>
            {hours.map((hour) => {
              return <Time key={hour} timeSlot={getTimeSlot(period, hour)} />;
            })}
          </div>
        </div>
      );
    });
  };

  return (
    <div className={style.container}>
      <span className={style.staff}>{staff.name}</span>
      {renderSection()}
    </div>
  );
}
