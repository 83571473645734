import { Route } from 'react-router-dom';
import { path } from '../Shared/Services/Path/Path';
import { Show } from './Containers/Show/Show';
import { ChangePassword } from './Containers/ChangePassword/ChangePassword';
import { Edit } from './Containers/Edit/Edit';

export const routes = (
  <>
    <Route path={path.profiles.profile} element={<Show />} />
    <Route path={path.profiles.changePassword} element={<ChangePassword />} />
    <Route path={path.profiles.edit} element={<Edit />} />
  </>
);
