import { Header } from '../../../Shared/Components/Header/Header.tsx';
import { Profile } from '../../../Profile/Components/Profile/Profile.tsx';
import { useParams } from 'react-router-dom';
import { useUser } from '../../Hooks/useUser/useUser.ts';
import { Loading } from '../../../Shared/Components/Loading/Loading.tsx';

export function Show() {
  const { key } = useParams();
  const { record: user, query } = useUser(key!);

  if (query.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header title="TimeFitter" goBack />
      <Profile user={user!} />
    </>
  );
}
