import { FirebaseError, initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  Auth,
  User as AuthUser,
  UserCredential,
  createUserWithEmailAndPassword,
  updateCurrentUser,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
} from 'firebase/auth';
import {
  FirebaseStorage,
  StorageReference,
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import {
  endBefore,
  get,
  getDatabase,
  limitToFirst,
  limitToLast,
  orderByKey,
  query,
  ref,
  startAfter,
  Query,
  Database,
  DatabaseReference,
  push,
  set,
  update,
  remove,
} from 'firebase/database';
import * as Sentry from '@sentry/react';
import {
  Credentials,
  Key,
  Keyable,
  PaginationParams,
  Post,
  PostPayload,
  Posts,
  PostWithAuthor,
  ResetPasswordPayload,
  Schedule,
  TimeSlot,
  UpdatePasswordPayload,
  User,
  UserAttributes,
  UserPayload,
  UpdateUserPayload,
  UserAttributesPayload,
  Users,
} from './Interfaces.ts';
import { PayloadError } from './Errors.ts';
import { removeUndefined } from '../Utils/Utils';

initializeApp({
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
});

const DEFAULT_ITEMS_PER_PAGE = 5;

class TimefitBackend {
  private readonly db: Database;
  private readonly auth: Auth;
  private readonly storage: FirebaseStorage;

  constructor() {
    this.auth = getAuth();
    this.db = getDatabase();
    this.storage = getStorage();
  }

  async signIn(credentials: Credentials) {
    const userCredential = await signInWithEmailAndPassword(this.auth, credentials.email, credentials.password);
    // TODO: Refactor tracking code in the future
    Sentry.setUser({ email: credentials.email });
    return userCredential;
  }

  signOut() {
    return this.auth.signOut();
  }

  async isSignedIn() {
    const currentUser = await this.getCurrentAuthUser();
    if (currentUser?.email) {
      // TODO: Refactor tracking code in the future
      Sentry.setUser({ email: currentUser.email });
    }
    return currentUser !== null;
  }

  async updatePost(key: string, payload: Partial<PostPayload>) {
    const postRef = this.getRef(`/posts/${key}`);
    const post: Partial<Post> = {
      title: payload.title,
      content: payload.content,
      mainImage: payload.mainImage && (await this.uploadPostImage(postRef, payload.mainImage)),
    };
    await update(postRef, removeUndefined(post));
    return post;
  }

  async getPosts(paginationParams: PaginationParams = {}): Promise<Posts> {
    const postsQuery = this.getPaginatedQuery(this.getRef('/posts'), paginationParams);
    const posts = await this.fetch<Record<string, Post>>(postsQuery);
    return Promise.all(
      this.toList(posts).map(async (post) => ({
        ...post,
        author: await this.getUser(post.author),
      })),
    );
  }

  async getUsers(paginationParams: PaginationParams = {}): Promise<Users> {
    const usersQuery = this.getPaginatedQuery(this.getRef('/users'), paginationParams);
    const users = await this.fetch<Record<Key, UserAttributes>>(usersQuery);
    return this.toList(users);
  }

  async getPost(key: string): Promise<PostWithAuthor> {
    const post = await this.fetch<Post>(this.getRef(`/posts/${key}`));
    const author = await this.getUser(post.author);
    return { ...post, author };
  }

  async createPost(payload: PostPayload) {
    const postRef = await push(this.getRef('/posts'));
    const post: Post = {
      ...payload,
      author: (await this.getCurrentAuthUser())!.uid,
      createdAt: new Date().getTime(),
      mainImage: await this.uploadPostImage(postRef, payload.mainImage),
    };
    await set(postRef, post);
    return post;
  }

  async createUserAuth(email: string, password: string) {
    try {
      return await createUserWithEmailAndPassword(this.auth, email, password);
    } catch (error) {
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case 'auth/email-already-in-use':
            throw new PayloadError({ email: 'E-mail já cadastrado' });
          case 'auth/invalid-email':
            throw new PayloadError({ email: 'E-mail inválido' });
          default:
            throw error;
        }
      }
      throw error;
    }
  }

  async createUserData(payload: UserPayload, userCredential: UserCredential) {
    const userRef = this.getRef(`/users/${userCredential.user.uid}`);
    const user: UserAttributes = {
      firstName: payload.name,
      phoneNumber: payload.phone,
    };
    await update(userRef, user);
  }

  async updateUser(uid: string, payload: UpdateUserPayload) {
    const userRef = this.getRef(`/users/${uid}`);
    const user: UserAttributesPayload = {
      firstName: payload.name,
      description: payload.description ? payload.description : null,
      profilePicture: payload.profilePicture && (await this.uploadUserImage(userRef, payload.profilePicture)),
    };
    await update(userRef, removeUndefined(user));
    return user;
  }

  uploadUserImage(userRef: DatabaseReference, file: File) {
    return this.uploadFile(this.getStorageRef(`/profilePictures/${userRef.key}`), file);
  }

  uploadPostImage(postRef: DatabaseReference, file: File) {
    return this.uploadFile(this.getStorageRef(`/postImages/${postRef.key}`), file);
  }

  getUser(key: string) {
    return this.fetchRecord<User>(this.getRef(`/users/${key}`));
  }

  async getSchedule() {
    return (await this.fetchPossiblyEmpty<Schedule>(this.getScheduleRef())) ?? ({} as Schedule);
  }

  setSchedule(schedule: Schedule) {
    // This is a workaround to avoid the "undefined" values on the database.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const scheduleData = JSON.parse(JSON.stringify(schedule));
    return set(this.getScheduleRef(), scheduleData);
  }

  async schedule(timeSlot: TimeSlot, user?: User) {
    let schedulingUser = user;
    if (!schedulingUser) {
      schedulingUser = await this.getCurrentUser();
    }
    return update(
      this.getTimeSlotRef(timeSlot),
      removeUndefined({
        clientName: schedulingUser.firstName,
        reservedBy: schedulingUser.key,
        clientPhoneNumber: schedulingUser.phoneNumber,
      }),
    );
  }

  async unschedule(timeSlot: TimeSlot) {
    return update(this.getTimeSlotRef(timeSlot), {
      clientName: null,
      clientPhoneNumber: null,
      reservedBy: null,
    });
  }

  resetPassword(payload: ResetPasswordPayload) {
    return sendPasswordResetEmail(this.auth, payload.email);
  }

  async deletePost(key: Key) {
    const imageRef = this.getStorageRef(`/postImages/${key}`);
    await deleteObject(imageRef);
    return remove(this.getRef(`/posts/${key}`));
  }

  async getCurrentUser() {
    const authUser = await this.getCurrentAuthUser();
    if (!authUser) {
      throw new Error('User is not signed in');
    }
    return this.getUser(authUser.uid);
  }

  async updatePassword(payload: UpdatePasswordPayload) {
    try {
      const currentUser = (await this.getCurrentAuthUser())!;
      const authCredential = EmailAuthProvider.credential(currentUser.email!, payload.currentPassword);
      await reauthenticateWithCredential(currentUser, authCredential);
      return await updatePassword(currentUser, payload.newPassword);
    } catch (error) {
      if (error instanceof FirebaseError && error.code === 'auth/wrong-password') {
        throw new PayloadError({ currentPassword: 'Senha incorreta' });
      }

      throw error;
    }
  }

  async getCurrentAuthUser() {
    await this.auth.authStateReady();
    return this.auth.currentUser;
  }

  setCurrentAuthUser(user: AuthUser) {
    return updateCurrentUser(this.auth, user);
  }

  private getScheduleRef() {
    return this.getRef('/agenda');
  }

  private getTimeSlotRef(timeSlot: TimeSlot) {
    return this.getRef(`/agenda/${timeSlot.key.day}/${timeSlot.key.timePeriod}/${timeSlot.key.key}`);
  }

  private toList<T>(collection: Record<Key, T>) {
    return Object.entries(collection).map(([key, value]) => ({ ...value, key }));
  }

  private getRef(path: string) {
    return ref(this.db, path);
  }

  private getStorageRef(path: string) {
    return storageRef(this.storage, path);
  }

  private async fetchPossiblyEmpty<T>(query: Query) {
    const snapshot = await get(query);
    return snapshot.val() as T | undefined;
  }

  private async fetch<T>(query: Query) {
    const result = await this.fetchPossiblyEmpty<T>(query);
    if (result === undefined) {
      throw new Error(`Not found: ${query.ref.key}`);
    }

    return result as T;
  }

  private async fetchRecord<T extends Keyable>(query: Query) {
    const record = await this.fetch<T>(query);
    return { ...record, key: query.ref.key } as T;
  }

  private async uploadFile(storageRef: StorageReference, file: File) {
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  }

  private getPaginatedQuery(
    collectionQuery: Query,
    { cursorKey, itemsPerPage = DEFAULT_ITEMS_PER_PAGE, direction = 'previous' }: PaginationParams,
  ) {
    const limit = direction === 'previous' ? limitToLast : limitToFirst;
    const end = direction === 'previous' ? endBefore : startAfter;
    let result = query(collectionQuery, orderByKey(), limit(itemsPerPage));
    if (cursorKey) {
      result = query(result, end(cursorKey));
    }
    return result;
  }
}

export const timefitBackend = new TimefitBackend();
