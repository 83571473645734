import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames/bind';
import style from './MenuItem.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

const cx = classNames.bind(style);

interface MenuItemProps extends React.PropsWithChildren {
  label: string;
  icon: IconProp;
  path: string;
  inactive?: boolean;
  subItem?: boolean;
}

export function MenuItem({ label, icon, path, subItem, inactive, children }: MenuItemProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const active = location.pathname.startsWith(path) && !inactive;

  const handleNavigation = () => {
    navigate(path);
  };

  return (
    <>
      <div className={cx('menuItem', { active, subItem })} onClick={handleNavigation}>
        <FontAwesomeIcon icon={icon} className={style.icon} />
        <span className={style.label}>{label}</span>
      </div>
      <div>{children}</div>
    </>
  );
}
