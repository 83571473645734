import { Header } from '../../../Shared/Components/Header/Header.tsx';
import { CreateUserForm, CreateUserFormValues } from '../../Components/CreateUserForm/CreateUserForm.tsx';
import { useNavigate } from 'react-router-dom';
import { path } from '../../../Shared/Services/Path/Path.ts';
import { useAdminCreateUser } from '../../Hooks/useAdminCreateUser/useAdminCreateUser.ts';

export function Create() {
  const create = useAdminCreateUser();
  const navigate = useNavigate();

  const handleSubmit = async (values: CreateUserFormValues) => {
    await create.mutateAsync(values);
    navigate(path.timeline.list);
  };

  return (
    <>
      <Header title="Novo Timefitter" />
      <CreateUserForm loading={create.isLoading} onSubmit={handleSubmit} />
    </>
  );
}
