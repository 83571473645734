import { Route } from 'react-router-dom';
import { path } from '../Shared/Services/Path/Path';
import { Create } from './Containers/Create/Create';
import { List } from './Containers/List/List';
import { Show } from './Containers/Show/Show.tsx';

export const routes = (
  <>
    <Route path={path.users.create} element={<Create />} />
    <Route path={path.users.list} element={<List />} />
    <Route path={path.users.show} element={<Show />} />
  </>
);
