import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import style from './ResponsiveImage.module.css';
import classNames from 'classnames/bind';
import React from 'react';

const cx = classNames.bind(style);

interface ResponsiveImageProps extends React.ComponentPropsWithoutRef<'img'> {
  variant: 'square' | 'header';
}

export function ResponsiveImage({ variant, ...otherProps }: ResponsiveImageProps) {
  const renderImage = () => {
    return <img className={style.image} {...otherProps} />;
  };

  const renderFallback = () => {
    return (
      <div className={style.iconContainer}>
        <FontAwesomeIcon icon={faImage} className={style.icon} />
      </div>
    );
  };

  return <div className={cx('container', variant)}>{otherProps.src ? renderImage() : renderFallback()}</div>;
}
