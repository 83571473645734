import { useMutation } from 'react-query';
import { UserPayload, timefitBackend } from '../../../Shared/Services/TimefitBackend';

export function useCreateUser() {
  const mutation = async (payload: UserPayload) => {
    const userCredential = await timefitBackend.createUserAuth(payload.email, payload.password);
    await timefitBackend.createUserData(payload, userCredential);
  };

  return useMutation(mutation);
}
