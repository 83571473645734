import style from './Section.module.css';

interface SectionProps {
  label?: string;
}

export function Section({ label, children }: React.PropsWithChildren<SectionProps>) {
  return (
    <div className={style.container}>
      {label && <span className={style.label}>{label}</span>}
      {children}
    </div>
  );
}
