import React, { createContext } from 'react';
import { Day, Key, Schedule, TimeSlotAttributes, TimeSlots } from '../../../Shared/Services/TimefitBackend';
import { v4 as uuidv4 } from 'uuid';
import { useSelectedDayKey } from '../../Hooks/useSelectedDayKey/useSelectedDayKey.ts';
import { compareTimeSlots } from '../../../Shared/Services/Utils/Utils.ts';

interface ScheduleContext {
  isActive: (timeSlot: TimeSlotAttributes) => [Key, TimeSlotAttributes] | undefined;

  toggleActive: (timeSlot: TimeSlotAttributes) => void;
}

export const ScheduleContext = createContext<ScheduleContext>({} as ScheduleContext);

interface ScheduleProviderProps {
  newSchedule: Schedule;
  setNewSchedule: React.Dispatch<React.SetStateAction<Schedule>>;
}

export function ScheduleProvider({
  newSchedule,
  setNewSchedule,
  children,
}: React.PropsWithChildren<ScheduleProviderProps>) {
  const selectedDayKey = useSelectedDayKey();

  const addTimeSlot = (timeSlots: TimeSlots, timeSlot: TimeSlotAttributes): TimeSlots => {
    return {
      ...timeSlots,
      [uuidv4()]: timeSlot,
    };
  };

  const removeTimeSlot = (timeSlots: TimeSlots, timeSlot: TimeSlotAttributes): TimeSlots => {
    const foundTimeSlotEntry = Object.entries(timeSlots).find(([, t]) => compareTimeSlots(t, timeSlot));

    if (foundTimeSlotEntry) {
      const result = { ...timeSlots };
      delete result[foundTimeSlotEntry[0]];
      return result;
    }

    return timeSlots;
  };

  const toggleActive = (timeSlot: TimeSlotAttributes) => {
    const operation = isActive(timeSlot) ? removeTimeSlot : addTimeSlot;

    setNewSchedule((newSchedule) => {
      const selectedDay = newSchedule[selectedDayKey] ?? ({} as Day);
      const timeSlots = selectedDay[timeSlot.timePeriod] ?? ({} as TimeSlots);

      return {
        ...newSchedule,
        [selectedDayKey]: {
          ...selectedDay,
          [timeSlot.timePeriod]: operation(timeSlots, timeSlot),
        },
      } as Schedule;
    });
  };

  const isActive = (timeSlot: TimeSlotAttributes): [Key, TimeSlotAttributes] | undefined => {
    const selectedDay = newSchedule[selectedDayKey];
    if (!selectedDay) {
      return;
    }

    const timeSlots = selectedDay[timeSlot.timePeriod];

    if (!timeSlots) {
      return;
    }

    return Object.entries(timeSlots).find(([, t]) => compareTimeSlots(t, timeSlot));
  };

  return <ScheduleContext.Provider value={{ toggleActive, isActive }}>{children}</ScheduleContext.Provider>;
}
