import { faAddressBook, faBell, faBuilding, faNewspaper, faUser } from '@fortawesome/free-regular-svg-icons';
import { MenuItem } from './MenuItem/MenuItem.tsx';
import { SignOut } from './SignOut/SignOut.tsx';
import { generatePath, Outlet, useLocation } from 'react-router-dom';
import { path } from '../../Services/Path/Path.ts';
import { useCallback, useEffect, useState } from 'react';
import style from './Sidebar.module.css';
import classNames from 'classnames/bind';
import { createContext } from 'react';
import { defaultUnit } from '../../Services/Utils/Utils.ts';

export interface SidebarContextProps {
  toggleSidebar: () => void;
}

export const SidebarContext = createContext<SidebarContextProps | null>(null);

const cx = classNames.bind(style);

export function Sidebar() {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const toggleSidebar = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  // Closes Sidebar whenever location changes
  useEffect(() => {
    setOpen(false);
  }, [location]);

  const handleOutClick = () => {
    if (open) {
      setOpen(false);
    }
  };
  const getUnitPath = (unit: string) => {
    return generatePath(path.scheduling.list, { unit });
  };

  const renderSidebar = () => {
    return (
      <div className={style.sidebar}>
        <MenuItem label="Timeline" icon={faNewspaper} path={path.timeline.list} />
        <MenuItem label="Perfil" icon={faUser} path={path.profiles.profile} />
        <MenuItem inactive label="Agendamento" icon={faBell} path={getUnitPath(defaultUnit)}>
          <MenuItem subItem label="Jardim Europa" icon={faBuilding} path={getUnitPath('jardim-europa')} />
          <MenuItem subItem label="Grageru" icon={faBuilding} path={getUnitPath('grageru')} />
          <MenuItem subItem label="Arena Calu" icon={faBuilding} path={getUnitPath('arena-calu')} />
        </MenuItem>
        <MenuItem label="Timefitters" icon={faAddressBook} path={path.users.list} />
        <SignOut />
      </div>
    );
  };

  return (
    <SidebarContext.Provider value={{ toggleSidebar }}>
      <div className={cx({ container: open })}>
        {open && renderSidebar()}
        <div className={cx({ routes: open })} onClick={handleOutClick}>
          <div className={cx({ preventClick: open })}>
            <Outlet />
          </div>
        </div>
      </div>
    </SidebarContext.Provider>
  );
}
