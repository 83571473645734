import { redirect, Route } from 'react-router-dom';
import { routes as AuthenticationRoutes } from './Authentication/routes.tsx';
import { routes as TimelineRoutes } from './Timeline/routes.tsx';
import { routes as SchedulingRoutes } from './Scheduling/routes.tsx';
import { routes as UsersRoutes } from './Users/routes.tsx';
import { routes as ProfileRoutes } from './Profile/routes.tsx';
import { timefitBackend } from './Shared/Services/TimefitBackend/TimefitBackend.ts';
import { path } from './Shared/Services/Path/Path.ts';
import { Sidebar } from './Shared/Components/Sidebar/Sidebar.tsx';

function getUrl(request: Request) {
  return new URL(request.url);
}

async function protectAuthenticatedOnlyRoutes(request: Request) {
  const url = getUrl(request);
  const isRootPage = url.pathname === '/';
  const isSignInPage = url.pathname === path.authentication.signIn;
  const isProtectedPage = !isSignInPage;
  const isUserSignedIn = await timefitBackend.isSignedIn();

  if (isUserSignedIn && (isRootPage || isSignInPage)) {
    return redirect(path.timeline.list);
  }

  if (isProtectedPage && !isUserSignedIn) {
    return redirect(path.authentication.signIn);
  }
}

function toRegExp(path: string) {
  return new RegExp(path.replace(/\//g, '\\/').replace(/:\w+/g, '.*'));
}

async function protectAdminOnlyRoutes(request: Request) {
  if (!(await timefitBackend.isSignedIn())) {
    return;
  }

  const currentUser = await timefitBackend.getCurrentUser();

  const onlyAdminPages = [path.timeline.create, path.users.create, path.scheduling.edit].map(toRegExp);
  const isAdminOnlyPage = !!onlyAdminPages.find((page) => page.test(getUrl(request).pathname));

  const isAdmin = currentUser.admin;

  if (isAdminOnlyPage && !isAdmin) {
    return redirect(path.timeline.list);
  }
}

async function loader({ request }: { request: Request }) {
  return (await protectAuthenticatedOnlyRoutes(request)) ?? (await protectAdminOnlyRoutes(request)) ?? null;
}

export const routes = (
  <Route path="/" element={<Sidebar />} loader={loader}>
    {AuthenticationRoutes}
    {TimelineRoutes}
    {SchedulingRoutes}
    {UsersRoutes}
    {ProfileRoutes}
  </Route>
);
