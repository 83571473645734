import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import 'normalize.css';
import './stack-levels.css';
import './fonts.css';
import './colors.css';
import './index.css';
import { routes } from './routes.tsx';
import moment from 'moment';
import 'moment/dist/locale/pt-br';
import './setupSentry.ts';
import './setupYup.ts';

moment.locale('pt-br');

const queryClient = new QueryClient();

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(createRoutesFromElements(routes));

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>,
);
