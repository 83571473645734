import { useMutation, useQueryClient } from 'react-query';
import { Key, timefitBackend } from '../../../Shared/Services/TimefitBackend';
import { keys } from '../../../Shared/Services/Query/Keys';

export function useDeletePost() {
  const queryClient = useQueryClient();

  const mutation = (key: Key) => {
    return timefitBackend.deletePost(key);
  };

  return useMutation(mutation, {
    onSuccess: () => {
      void queryClient.removeQueries(keys.posts.list());
    },
  });
}
