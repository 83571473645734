import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResponsiveImage } from '../../../Shared/Components/ResponsiveImage/ResponsiveImage';
import style from './Card.module.css';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { User } from '../../../Shared/Services/TimefitBackend';
import { limitText } from '../../../Shared/Services/Utils/Utils';

interface CardProps {
  user: User;
  onClick?: () => void;
}

export function Card({ user, onClick }: CardProps) {
  return (
    <div className={style.card} onClick={() => onClick && onClick()}>
      <ResponsiveImage variant="header" src={user.profilePicture} />
      <div className={style.container}>
        <div>
          <span className={style.title}>{user.firstName}</span>
          <span className={style.label}>{user.description && 'OBJETIVO'}</span>
          <span className={style.goals}>{user.description ? limitText(user.description, 24) : ''}</span>
        </div>
        <FontAwesomeIcon icon={faCircleArrowRight} className={style.icon} />
      </div>
    </div>
  );
}
