import { timefitBackend } from '../../../Shared/Services/TimefitBackend';
import { keys } from '../../../Shared/Services/Query/Keys.ts';
import { useRecord } from '../../../Shared/Hooks/useRecord/useRecord.ts';

export function useSchedule() {
  const queryFn = () => {
    return timefitBackend.getSchedule();
  };

  return useRecord(keys.schedule.list(), queryFn);
}
