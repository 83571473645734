import { Field } from '../../../Shared/Components/Field/Field';
import Form from '../../../Shared/Components/Form/Form';
import { Section } from '../../../Shared/Components/Section/Section';
import { SubmitBtn } from '../../../Shared/Components/SubmitBtn/SubmitBtn';
import * as Yup from 'yup';

export interface ChangePasswordFormValues {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

interface ChangePasswordFormProps {
  loading: boolean;
  onSubmit: (values: ChangePasswordFormValues) => Promise<unknown>;
}

export function ChangePasswordForm({ loading, onSubmit }: ChangePasswordFormProps) {
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required().min(6),
    newPassword: Yup.string().required().min(6),
    confirmNewPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('newPassword')], 'As senhas não são iguais'),
  });

  return (
    <Form
      initialValues={{ currentPassword: '', newPassword: '', confirmNewPassword: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Section>
        <Field name="currentPassword" label="Senha atual" type="password" />
        <Field name="newPassword" label="Nova senha" type="password" />
        <Field name="confirmNewPassword" label="Confirme sua nova senha" type="password" />
      </Section>
      <SubmitBtn loading={loading}>Alterar Senha</SubmitBtn>
    </Form>
  );
}
