import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './Loading.module.css';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export function Loading() {
  return (
    <div className={style.loadingContainer}>
      <FontAwesomeIcon icon={faCircleNotch} spin className={style.loading} />
    </div>
  );
}
