import { Layout } from '../../Components/Layout/Layout.tsx';
import { SignInForm } from '../../Components/SignInForm/SignInForm.tsx';

export function SignIn() {
  return (
    <Layout>
      <SignInForm />
    </Layout>
  );
}
