import { User } from '../../../Shared/Services/TimefitBackend';
import style from './Profile.module.css';
import { ResponsiveImage } from '../../../Shared/Components/ResponsiveImage/ResponsiveImage.tsx';

interface ProfileProps {
  user: User;
}

export function Profile({ user }: ProfileProps) {
  return (
    <>
      <ResponsiveImage variant="square" src={user.profilePicture} />
      <div className={style.container}>
        <span className={style.name}>{user.firstName}</span>
        <span className={style.label}>OBJETIVO</span>
        <span className={style.goals}>{user.description ?? 'Edite seu perfil e adicione um novo objetivo!'}</span>
      </div>
    </>
  );
}
