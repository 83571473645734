import { ForgotPasswordForm } from '../../Components/ForgotPasswordForm/ForgotPasswordForm';
import { Layout } from '../../Components/Layout/Layout';

export function ForgotPassword() {
  return (
    <Layout>
      <ForgotPasswordForm />
    </Layout>
  );
}
