import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import style from './Avatar.module.css';
import { User } from '../../Services/TimefitBackend';

interface AvatarProps {
  user: User;
}

export function Avatar({ user }: AvatarProps) {
  const renderImage = () => {
    return <img className={style.avatar} src={user.profilePicture} alt={user.firstName} />;
  };

  const renderFallback = () => {
    return (
      <div className={style.avatar}>
        <FontAwesomeIcon icon={faUser} className={style.icon} />
      </div>
    );
  };

  return user.profilePicture ? renderImage() : renderFallback();
}
