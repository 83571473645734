import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './FAB.module.css';
import { IconDefinition, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Button } from '../Button/Button.tsx';

interface FloatingActionButtonProps extends React.PropsWithChildren {
  icon?: IconDefinition;
  closeIcon?: IconDefinition;
  onClick?: () => void;
}

function FloatingActionButton({
  icon: openIcon = faPlus,
  closeIcon = faXmark,
  onClick,
  children,
}: FloatingActionButtonProps) {
  const [opened, setOpened] = useState(false);
  const [icon, setIcon] = useState(openIcon);

  useEffect(() => {
    setIcon(opened ? closeIcon : openIcon);
  }, [closeIcon, openIcon, opened]);

  const toggleDropdown = () => {
    setOpened((opened) => !opened);
  };

  const handleClick = () => {
    onClick && onClick();
    toggleDropdown();
  };

  return (
    <div className={style.container}>
      {opened && <div className={style.optionsContainer}>{children}</div>}
      <div className={style.buttonContainer}>
        <Button className={style.button} onClick={handleClick}>
          <FontAwesomeIcon icon={icon} />
        </Button>
      </div>
    </div>
  );
}

interface FABOptionProps {
  icon: IconDefinition;
  onClick: () => void;
  label: string;
}

function FABOption({ icon, label, onClick }: FABOptionProps) {
  return (
    <div className={style.optionContainer}>
      <div className={style.labelContainer}>
        <span className={style.label}>{label}</span>
      </div>
      <Button className={style.button} onClick={onClick}>
        <FontAwesomeIcon icon={icon} />
      </Button>
    </div>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export const FAB = {
  Button: FloatingActionButton,
  Option: FABOption,
};
