import { useMutation, useQueryClient } from 'react-query';
import { PostPayload, timefitBackend } from '../../../Shared/Services/TimefitBackend';
import { keys } from '../../../Shared/Services/Query/Keys.ts';

export function useCreatePost() {
  const queryClient = useQueryClient();

  const mutation = (payload: PostPayload) => {
    return timefitBackend.createPost(payload);
  };

  return useMutation(mutation, {
    onSuccess: () => {
      void queryClient.invalidateQueries(keys.posts.list());
    },
  });
}
