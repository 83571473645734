import { timefitBackend } from '../../../Shared/Services/TimefitBackend';
import { keys } from '../../../Shared/Services/Query/Keys.ts';
import { useInfiniteList } from '../../../Shared/Hooks/useInfiniteList/useInfiniteList.ts';

export function useUsersInfiniteList() {
  const queryFn = ({ pageParam = undefined }) => {
    return timefitBackend.getUsers({ cursorKey: pageParam });
  };

  return useInfiniteList(keys.users.list(), queryFn);
}
