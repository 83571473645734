import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ButtonHTMLAttributes } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

export function Button({ loading = false, children, ...otherProps }: React.PropsWithChildren<ButtonProps>) {
  return (
    <button {...otherProps} disabled={loading || otherProps.disabled}>
      {loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : children}
    </button>
  );
}
