import { Header } from '../../../Shared/Components/Header/Header.tsx';
import { CreatePostForm, CreatePostFormValues } from '../../Components/CreatePostForm/CreatePostForm.tsx';
import { useCreatePost } from '../../Hooks/useCreatePost/useCreatePost.ts';
import { PostPayload } from '../../../Shared/Services/TimefitBackend';
import { path } from '../../../Shared/Services/Path/Path.ts';
import { useNavigate } from 'react-router-dom';

export function Create() {
  const create = useCreatePost();
  const navigate = useNavigate();

  const handleSubmit = async (values: CreatePostFormValues) => {
    await create.mutateAsync(values as PostPayload);
    navigate(path.timeline.list);
  };

  return (
    <>
      <Header goBack title="Novo Post" />
      <CreatePostForm loading={create.isLoading} onSubmit={handleSubmit} />
    </>
  );
}
