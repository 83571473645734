import { generatePath, redirect, Route } from 'react-router-dom';
import moment from 'moment';
import { path } from '../Shared/Services/Path/Path.ts';
import { Show } from './Containers/Show/Show.tsx';
import { Edit } from './Containers/Edit/Edit.tsx';
import { defaultUnit } from '../Shared/Services/Utils/Utils.ts';

type Params<Key extends string = string> = {
  readonly [key in Key]: string | undefined;
};

function redirectToCurrentDay({ params }: { params: Params }) {
  const today = moment().format('d');
  return redirect(generatePath(path.scheduling.show, { day: today === '0' ? '1' : today, unit: params.unit }));
}

function redirectToList() {
  return redirect(generatePath(path.scheduling.list, { unit: defaultUnit }));
}

function redirectToShow({ params }: { params: Params }) {
  return redirect(generatePath(path.scheduling.show, { day: params.day, unit: defaultUnit }));
}
function redirectToEdit({ params }: { params: Params }) {
  return redirect(generatePath(path.scheduling.edit, { day: params.day, unit: defaultUnit }));
}

export const routes = (
  <>
    <Route path={path.scheduling.list} loader={redirectToCurrentDay} />
    <Route path={path.scheduling.oldList} loader={redirectToList} />
    <Route path={path.scheduling.oldShow} loader={redirectToShow} />
    <Route path={path.scheduling.oldEdit} loader={redirectToEdit} />
    <Route path={path.scheduling.show} element={<Show />} />
    <Route path={path.scheduling.edit} element={<Edit />} />
  </>
);
