import { useMutation, useQueryClient } from 'react-query';
import { timefitBackend, TimeSlot } from '../../../Shared/Services/TimefitBackend';
import { keys } from '../../../Shared/Services/Query/Keys.ts';

export function useUnscheduleTimeSlot() {
  const queryClient = useQueryClient();

  const mutation = (timeSlot: TimeSlot) => timefitBackend.unschedule(timeSlot);

  return useMutation(mutation, {
    onSuccess: () => {
      void queryClient.invalidateQueries(keys.schedule.list());
    },
  });
}
