import { useMutation, useQueryClient } from 'react-query';
import { timefitBackend } from '../../Services/TimefitBackend';

export function useSignOut() {
  const queryClient = useQueryClient();

  const mutation = () => timefitBackend.signOut();

  return useMutation(mutation, {
    onSuccess: () => {
      void queryClient.removeQueries();
    },
  });
}
