export const path = {
  authentication: {
    signIn: '/entrar',
    forgotPassword: '/esqueci-minha-senha',
    create: '/criar-conta',
  },
  timeline: {
    list: '/timeline',
    show: '/timeline/posts/:key',
    create: '/timeline/posts/criar',
    edit: '/timeline/posts/:key/editar',
  },
  scheduling: {
    list: '/agendamento/:unit',
    oldList: '/agendamento',
    show: '/agendamento/:unit/dias/:day',
    oldShow: '/agendamento/dias/:day',
    edit: '/agendamento/:unit/dias/:day/editar',
    oldEdit: '/agendamento/dias/:day/editar',
  },
  users: {
    list: '/timefitters',
    create: '/timefitters/perfis/criar',
    show: '/timefitters/perfis/:key',
  },
  profiles: {
    profile: '/perfil',
    changePassword: '/perfil/alterar-senha',
    edit: '/perfil/editar',
  },
};
