import style from './SignOut.module.css';
import { useNavigate } from 'react-router-dom';
import { path } from '../../../Services/Path/Path.ts';
import { useSignOut } from '../../../Hooks/useSignOut/useSignOut.ts';
import { Button } from '../../Button/Button.tsx';

export function SignOut() {
  const navigate = useNavigate();
  const signOut = useSignOut();

  const handleSignOut = async () => {
    await signOut.mutateAsync();
    navigate(path.authentication.signIn);
  };

  return (
    <div className={style.buttonContainer}>
      <Button
        loading={signOut.isLoading}
        className={style.button}
        onClick={() => {
          void handleSignOut();
        }}
      >
        SAIR
      </Button>
    </div>
  );
}
