import { ChangeEvent, InputHTMLAttributes } from 'react';
import IMask from 'imask';

export function MaskedInput(props: InputHTMLAttributes<HTMLInputElement>) {
  const masked = IMask.createMask({
    mask: '(00) 00000-0000',
  });
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.type === 'tel') {
      masked.resolve(event.target.value);
      event.target.value = masked.value;
    }
    props.onChange && props.onChange(event);
  };
  const getMaxLength = () => {
    if (props.type === 'tel') {
      return 15;
    }
  };

  return <input maxLength={getMaxLength()} {...props} onChange={handleChange} />;
}
