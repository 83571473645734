import React from 'react';
import { Formik, Form as FormikForm, FormikConfig, FormikValues, FormikHelpers, FormikErrors } from 'formik';
import style from './Form.module.css';
import { PayloadError } from '../../Services/TimefitBackend';

interface CustomFormikConfig<T> extends FormikConfig<T> {
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => Promise<unknown>;
}

function Form<T extends FormikValues>({ children, ...props }: React.PropsWithChildren<CustomFormikConfig<T>>) {
  const handleSubmit = async (values: T, formikHelpers: FormikHelpers<T>) => {
    try {
      await props.onSubmit(values, formikHelpers);
    } catch (error) {
      if (error instanceof PayloadError) {
        formikHelpers.setErrors(error.errors as FormikErrors<T>);
      } else {
        throw error;
      }
    }
  };

  return (
    <Formik {...props} onSubmit={handleSubmit}>
      <FormikForm className={style.container}>{children}</FormikForm>
    </Formik>
  );
}

export default Form;
