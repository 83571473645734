import { Card } from '../../Components/Card/Card.tsx';
import { Header } from '../../../Shared/Components/Header/Header.tsx';
import { usePostsInfiniteList } from '../../Hooks/usePostsInfiniteList/usePostsInfiniteList.ts';
import { generatePath, useNavigate } from 'react-router-dom';
import { path } from '../../../Shared/Services/Path/Path.ts';
import { FAB } from '../../../Shared/Components/FAB/FAB.tsx';
import { faPenToSquare, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useCurrentUser } from '../../../Shared/Hooks/useCurrentUser/useCurrentUser.ts';
import { useLoadMorePages } from '../../../Shared/Hooks/useLoadMorePages/useLoadMorePages.ts';

export function List() {
  const { records: posts, query } = usePostsInfiniteList();
  const navigate = useNavigate();
  const { record: currentUser } = useCurrentUser();
  const isAdmin = currentUser?.admin;

  useLoadMorePages(query);

  const sortedPosts = [...posts].reverse();

  return (
    <>
      <Header />
      {sortedPosts.map((post) => (
        <Card
          key={post.key}
          post={post}
          onClick={() => navigate(generatePath(path.timeline.show, { key: post.key }))}
        />
      ))}
      {isAdmin && (
        <FAB.Button>
          <FAB.Option icon={faPenToSquare} label="Criar Post" onClick={() => navigate(path.timeline.create)} />
          <FAB.Option icon={faUserPlus} label="Criar Timefitter" onClick={() => navigate(path.users.create)} />
        </FAB.Button>
      )}
    </>
  );
}
