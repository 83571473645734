import { Header } from '../../../Shared/Components/Header/Header';
import { useCurrentUser } from '../../../Shared/Hooks/useCurrentUser/useCurrentUser';
import { Profile } from '../../Components/Profile/Profile';
import { Loading } from '../../../Shared/Components/Loading/Loading';
import { FAB } from '../../../Shared/Components/FAB/FAB';
import { faLock, faUserPen } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { path } from '../../../Shared/Services/Path/Path';

export function Show() {
  const { query, record: user } = useCurrentUser();
  const navigate = useNavigate();

  if (query.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header title="Perfil" />
      <Profile user={user!} />
      <FAB.Button>
        <FAB.Option icon={faLock} label="Alterar Senha" onClick={() => navigate(path.profiles.changePassword)} />
        <FAB.Option icon={faUserPen} label="Editar Perfil" onClick={() => navigate(path.profiles.edit)} />
      </FAB.Button>
    </>
  );
}
