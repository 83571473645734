import Form from '../../../Shared/Components/Form/Form.tsx';
import { Field } from '../Field/Field.tsx';
import { FormikValues } from 'formik';
import { useNavigate } from 'react-router-dom';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { SubmitBtn } from '../../../Shared/Components/SubmitBtn/SubmitBtn.tsx';
import { faArrowRight, faLock } from '@fortawesome/free-solid-svg-icons';
import { path } from '../../../Shared/Services/Path/Path.ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSignIn } from '../../../Shared/Hooks/useSignIn/useSignIn.ts';
import { Link } from '../Link/Link.tsx';
import style from './SignInForm.module.css';

interface FormValues {
  email: string;
  password: string;
}

export function SignInForm() {
  const navigate = useNavigate();
  const signIn = useSignIn();

  const handleSubmit = async (values: FormikValues) => {
    try {
      await signIn.mutateAsync(values as FormValues);
      navigate(path.timeline.list);
    } catch (error) {
      alert('E-mail ou senha inválido.');
    }
  };

  return (
    <div className={style.container}>
      <Form initialValues={{ email: '', password: '' }} onSubmit={handleSubmit}>
        <Field icon={faUser} name="email" type="email" placeholder="digite seu usuário" />
        <Field icon={faLock} name="password" type="password" placeholder="digite sua senha" />
        <SubmitBtn loading={signIn.isLoading}>
          Entrar <FontAwesomeIcon icon={faArrowRight} />
        </SubmitBtn>
        <div className={style.links}>
          <Link to={path.authentication.create}>Criar conta</Link>
          <Link to={path.authentication.forgotPassword}>Esqueceu a senha?</Link>
        </div>
      </Form>
    </div>
  );
}
