import { PostWithAuthor } from '../../../Shared/Services/TimefitBackend';
import { limitText } from '../../../Shared/Services/Utils/Utils';
import style from './Card.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';
import { Avatar } from '../../../Shared/Components/Avatar/Avatar.tsx';
import { ResponsiveImage } from '../../../Shared/Components/ResponsiveImage/ResponsiveImage.tsx';

const cx = classNames.bind(style);

interface CardProps {
  post: PostWithAuthor;
  expanded?: boolean;
  onClick?: () => void;
}
export function Card({ post, expanded, onClick }: CardProps) {
  const postContent = () => (expanded ? post.content : limitText(post.content));

  return (
    <div className={style.card} onClick={() => onClick && onClick()}>
      <div className={style.profileContainer}>
        <Avatar user={post.author} />
        <span className={style.profileName}>{post.author.firstName}</span>
      </div>
      <ResponsiveImage variant="header" src={post.mainImage} />
      <div className={style.contentContainer}>
        <span className={style.contentTitle}>{post.title}</span>
        <p className={cx({ expanded })}>{postContent()}</p>
        {!expanded && (
          <div className={style.iconContainer}>
            <FontAwesomeIcon icon={faCircleArrowRight} className={style.icon} />
          </div>
        )}
      </div>
    </div>
  );
}
