import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './Header.module.css';
import { faArrowLeft, faBars } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useSidebar } from '../../Hooks/useSidebar/useSidebar.ts';

interface HeaderProps {
  title?: string;
  goBack?: boolean;
  goBackPath?: string;
  action?: any;
}

export function Header({ title, goBack, goBackPath, action }: HeaderProps) {
  const navigate = useNavigate();
  const { toggleSidebar } = useSidebar();

  const renderAction = () => {
    if (goBack) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return <FontAwesomeIcon icon={faArrowLeft} onClick={() => navigate(goBackPath ?? -1)} />;
    }

    return <FontAwesomeIcon icon={faBars} onClick={() => toggleSidebar()} />;
  };

  return (
    <>
      <div className={style.space}></div>
      <header className={style.headerContainer}>
        <div className={style.iconContainer}>{renderAction()}</div>
        <div className={style.imgContainer}>
          {title ? (
            <span className={style.title}>{title}</span>
          ) : (
            <img className={style.img} src="/assets/timefit_full_white.png" alt="Timefit Studio" />
          )}
        </div>
        {action || <div className={style.emptyIcon}></div>}
      </header>
    </>
  );
}
