import { useMutation, useQueryClient } from 'react-query';
import { Schedule, timefitBackend } from '../../../Shared/Services/TimefitBackend';
import { keys } from '../../../Shared/Services/Query/Keys.ts';

export function useSetSchedule() {
  const queryClient = useQueryClient();

  const mutation = (schedule: Schedule) => timefitBackend.setSchedule(schedule);

  return useMutation(mutation, {
    onSuccess: () => {
      void queryClient.removeQueries(keys.schedule.list());
    },
  });
}
