const users = {
  list: () => ['users'],
  current: () => ['users', 'current'],
  one: (key: string) => ['users', key],
};

const posts = {
  list: () => ['posts'],
  one: (key: string) => ['posts', key],
};

const schedule = {
  list: () => ['schedule'],
  one: (key: string) => ['schedule', key],
};

export const keys = {
  posts,
  schedule,
  users,
};
