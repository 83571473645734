import { Header } from '../../../Shared/Components/Header/Header';
import { Card } from '../../Components/Card/Card';
import { useUsersInfiniteList } from '../../Hooks/useUsersInfiniteList/useUsersInfiniteList';
import { useLoadMorePages } from '../../../Shared/Hooks/useLoadMorePages/useLoadMorePages.ts';
import { generatePath, useNavigate } from 'react-router-dom';
import { path } from '../../../Shared/Services/Path/Path.ts';

export function List() {
  const { records: users, query } = useUsersInfiniteList();
  const navigate = useNavigate();

  useLoadMorePages(query);

  const sortedUsers = [...users].reverse().filter((users) => 'firstName' in users);

  return (
    <>
      <Header title="TimeFitters" />
      {sortedUsers.map((user) => (
        <Card key={user.key} user={user} onClick={() => navigate(generatePath(path.users.show, { key: user.key }))} />
      ))}
    </>
  );
}
