import Form from '../../../Shared/Components/Form/Form.tsx';
import { Field } from '../Field/Field.tsx';
import { faEnvelope, faUser } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faLock, faMobileScreen } from '@fortawesome/free-solid-svg-icons';
import { SubmitBtn } from '../../../Shared/Components/SubmitBtn/SubmitBtn.tsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '../Link/Link.tsx';
import { path } from '../../../Shared/Services/Path/Path.ts';
import * as Yup from 'yup';

export interface CreateUserFormValues {
  name: string;
  email: string;
  password: string;
  phone: string;
}

interface CreateUserFormProps {
  loading: boolean;
  onSubmit: (values: CreateUserFormValues) => Promise<unknown>;
}

export function CreateUserForm({ loading, onSubmit }: CreateUserFormProps) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    phone: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string().required().min(6),
  });

  return (
    <Form
      initialValues={{ name: '', phone: '', email: '', password: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Field icon={faUser} type="text" name="name" placeholder="digite seu nome" />
      <Field icon={faMobileScreen} type="tel" name="phone" placeholder="digite seu telefone" />
      <Field icon={faEnvelope} type="email" name="email" placeholder="digite seu email" />
      <Field icon={faLock} type="password" name="password" placeholder="digite sua senha" />
      <SubmitBtn loading={loading}>
        Criar <FontAwesomeIcon icon={faArrowRight} />
      </SubmitBtn>
      <Link to={path.authentication.signIn}>Voltar ao início</Link>
    </Form>
  );
}
