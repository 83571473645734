import { useField } from 'formik';
import style from './ImageInput.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);

interface ImageInputProps {
  name: string;
}

export function ImageInput({ name }: ImageInputProps) {
  const [field, meta, helpers] = useField<string | File>(name);
  const hasError = meta.error && meta.touched;

  const getImageUrl = (): string => {
    if (field.value instanceof File) {
      return URL.createObjectURL(field.value);
    }
    return field.value;
  };

  const renderImage = () => {
    if (getImageUrl()) {
      return <img className={style.image} src={getImageUrl()} />;
    }

    return (
      <div className={style.placeholderContainer}>
        <FontAwesomeIcon icon={faImage} />
      </div>
    );
  };

  return (
    <div className={style.container}>
      <span className={style.label}>Escolha uma imagem:</span>
      <div className={style.inputContainer}>
        <label htmlFor={name} className={cx('input', { errorBorder: hasError })}>
          {renderImage()}
        </label>
        <input
          className={style.hidden}
          id={name}
          name={name}
          type="file"
          accept="image/*"
          onChange={(event) => {
            void helpers.setTouched(true);
            void helpers.setValue(event.currentTarget.files![0] || '');
          }}
          onBlur={() => void helpers.setTouched(true)}
        />
      </div>
      {hasError ? (
        <div className={style.errorContainer}>
          <span className={style.errorMessage}>{meta.error}</span>
        </div>
      ) : null}
    </div>
  );
}
