import style from './Layout.module.css';
import React from 'react';

interface LayoutProps {
  title?: string;
}

export function Layout({ title = 'Sempre um tempinho para você', children }: React.PropsWithChildren<LayoutProps>) {
  return (
    <div className={style.container}>
      <div className={style.content}>
        <img className={style.logo} src="/assets/timefit_studio_full_white.png" alt="Timefit Studios Logo" />
        <h1 className={style.title}>{title}</h1>
      </div>
      <div>{children}</div>
    </div>
  );
}
