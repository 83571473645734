import { useState } from 'react';
import { TimeSlot } from '../../../Shared/Services/TimefitBackend';
import style from './ScheduleBtn.module.css';
import { useScheduleTimeSlot } from '../../Hooks/useScheduleTimeSlot/useScheduleTimeSlot';
import { useUnscheduleTimeSlot } from '../../Hooks/useUnscheduleTimeSlot/useUnscheduleTimeSlot';
import classNames from 'classnames/bind';
import { useCurrentUser } from '../../../Shared/Hooks/useCurrentUser/useCurrentUser.ts';
import { Button } from '../../../Shared/Components/Button/Button.tsx';

interface ScheduleBtnProps {
  timeSlot: TimeSlot;
}
const cx = classNames.bind(style);

export function ScheduleBtn({ timeSlot }: ScheduleBtnProps) {
  const { record: currentUser } = useCurrentUser();
  const schedule = useScheduleTimeSlot();
  const unschedule = useUnscheduleTimeSlot();
  const [confirming, setConfirming] = useState(false);

  const isAvailable = !timeSlot.reservedBy;
  const isLoading = schedule.isLoading || unschedule.isLoading;
  const isDisabled = !isAvailable && !currentUser?.admin;
  const reservedByCurrentUser = currentUser?.key === timeSlot.reservedBy;

  const handleSchedule = () => {
    schedule.mutate(timeSlot, {
      onSuccess: () => {
        setConfirming(false);
      },
    });
  };

  const handleUnschedule = () => {
    unschedule.mutate(timeSlot);
  };

  const renderButton = (label: string, onClick?: () => void) => {
    return (
      <Button
        loading={isLoading}
        disabled={isDisabled}
        className={cx('button', {
          confirming: confirming,
          disabled: isDisabled,
          reservedByCurrentUser: reservedByCurrentUser && !currentUser?.admin,
        })}
        onClick={onClick}
      >
        {label}
      </Button>
    );
  };

  if (!isAvailable && currentUser?.admin) {
    return renderButton('DESAGENDAR', handleUnschedule);
  }

  if (confirming) {
    return renderButton('CONFIRMAR', handleSchedule);
  }

  if (!isAvailable && reservedByCurrentUser) {
    return renderButton('AGENDADO');
  }

  if (!isAvailable) {
    return renderButton('OCUPADO');
  }

  return renderButton('AGENDAR', () => setConfirming(true));
}
