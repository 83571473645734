import moment, { Moment } from 'moment';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { path } from '../../../Shared/Services/Path/Path.ts';
import style from './Days.module.css';
import classNames from 'classnames/bind';
import { useSelectedDayKey } from '../../Hooks/useSelectedDayKey/useSelectedDayKey.ts';

const cx = classNames.bind(style);

export function Days({ editing }: any) {
  const navigate = useNavigate();
  const { unit } = useParams();
  const selectedDayKey = useSelectedDayKey();

  const days = [1, 2, 3, 4, 5, 6].map((day) => moment().startOf('week').add(day, 'days'));

  const handleClick = (day: Moment) => {
    navigate(generatePath(editing ? path.scheduling.edit : path.scheduling.show, { day: day.format('d'), unit }));
  };

  const isActive = (day: Moment) => {
    return day.format('d') === selectedDayKey;
  };

  return (
    <ul className={style.container}>
      {days.map((day) => (
        <li
          key={day.format('YYYY-MM-DD')}
          onClick={() => handleClick(day)}
          className={cx('item', { itemActive: isActive(day) })}
        >
          <span className={style.dayAbbreviation}>{day.format('ddd')}</span>
          <br />
          {day.format('D')}
        </li>
      ))}
    </ul>
  );
}
