import { useNavigate } from 'react-router-dom';
import { Header } from '../../../Shared/Components/Header/Header';
import { ChangePasswordForm, ChangePasswordFormValues } from '../../Components/ChangePasswordForm/ChangePasswordForm';
import { useUpdatePassword } from '../../Hooks/useUpdatePassword/useUpdatePassword';
import { path } from '../../../Shared/Services/Path/Path';

export function ChangePassword() {
  const update = useUpdatePassword();
  const navigate = useNavigate();

  const handleSubmit = async (values: ChangePasswordFormValues) => {
    await update.mutateAsync(values);
    navigate(path.timeline.list);
  };
  return (
    <>
      <Header title="Alterar senha" goBack />
      <ChangePasswordForm loading={update.isLoading} onSubmit={handleSubmit} />
    </>
  );
}
