type FieldName = string;
type ErrorMessage = string;
type Errors = Record<FieldName, ErrorMessage>;

export class PayloadError extends Error {
  // Record of errors. For example: { email: 'is already taken' }
  readonly errors: Errors;

  constructor(errors: Errors) {
    super(`There are errors in the payload: ${JSON.stringify(errors)}`);
    this.name = 'PayloadError';
    this.errors = errors;
  }
}
