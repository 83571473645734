import { useNavigate } from 'react-router-dom';
import { Header } from '../../../Shared/Components/Header/Header';
import { Loading } from '../../../Shared/Components/Loading/Loading';
import { useCurrentUser } from '../../../Shared/Hooks/useCurrentUser/useCurrentUser';
import { EditForm, EditFormValues } from '../../Components/EditForm/EditForm';
import { useUpdateUser } from '../../Hooks/useUpdateUser/useUpdateUser';
import { path } from '../../../Shared/Services/Path/Path';

export function Edit() {
  const { record: user, query } = useCurrentUser();
  // We'll never call update without a user, so we can safely use the non-null assertion operator here
  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const update = useUpdateUser(user?.key!);
  const navigate = useNavigate();

  if (query.isLoading) {
    return <Loading />;
  }

  const handleSubmit = async (values: EditFormValues) => {
    await update.mutateAsync({
      ...values,
      profilePicture: typeof values.profilePicture === 'string' ? undefined : values.profilePicture,
    });
    navigate(path.profiles.profile);
  };

  return (
    <>
      <Header title="Editar Perfil" goBack />
      <EditForm loading={update.isLoading} onSubmit={handleSubmit} user={user!} />
    </>
  );
}
