import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../../Shared/Components/Header/Header';
import { EditPostForm, EditPostFormValues } from '../../Components/EditPostForm/EditPostForm';
import { usePost } from '../../Hooks/usePost/usePost';
import { Loading } from '../../../Shared/Components/Loading/Loading';
import { useUpdatePost } from '../../Hooks/useUpdatePost/useUpdatePost';
import { path } from '../../../Shared/Services/Path/Path';

export function Edit() {
  const { key } = useParams();
  const { record: post, query } = usePost(key!);
  const update = useUpdatePost(key!);
  const navigate = useNavigate();

  const handleSubmit = async (values: EditPostFormValues) => {
    await update.mutateAsync({
      ...values,
      mainImage: typeof values.mainImage === 'string' ? undefined : values.mainImage,
    });
    navigate(generatePath(path.timeline.show, { key: key }));
  };

  if (query.isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header title="Editar Post" goBack goBackPath={generatePath(path.timeline.show, { key })} />
      <EditPostForm loading={update.isLoading} post={post!} onSubmit={handleSubmit} />
    </>
  );
}
