import { Layout } from '../../Components/Layout/Layout.tsx';
import { CreateUserForm, CreateUserFormValues } from '../../Components/CreateUserForm/CreateUserForm.tsx';
import { useCreateUser } from '../../Hooks/useCreateUser/useCreateUser.ts';
import { useNavigate } from 'react-router-dom';
import { path } from '../../../Shared/Services/Path/Path.ts';

export function Create() {
  const create = useCreateUser();
  const navigate = useNavigate();

  const handleSubmit = async (values: CreateUserFormValues) => {
    await create.mutateAsync(values);
    navigate(path.timeline.list);
  };
  return (
    <Layout title="Criar Conta">
      <CreateUserForm onSubmit={handleSubmit} loading={create.isLoading} />
    </Layout>
  );
}
