import { Schedule, TimePeriod, TimeSlotAttributes } from '../TimefitBackend';

export const defaultUnit = 'jardim-europa';

export function limitText(text: string, limit = 250) {
  return text.length <= limit ? text : text.substring(0, limit) + '...';
}

export function getTimePeriod(timePeriod: TimePeriod) {
  const timePeriodMap = {
    morning: 'Manhã',
    evening: 'Tarde',
    night: 'Noite',
  };
  return timePeriodMap[timePeriod];
}

export function byPeriod(a: [string, unknown], b: [string, unknown]) {
  if (a[0] === 'morning') return -1;
  if (b[0] === 'morning') return 1;
  if (a[0] === 'evening') return -1;
  if (b[0] === 'evening') return 1;
  if (a[0] === 'night') return -1;
  if (b[0] === 'night') return 1;
  return 0;
}

export function byHour(a: [unknown, TimeSlotAttributes], b: [unknown, TimeSlotAttributes]) {
  if (a[1].hour < b[1].hour) return -1;
  if (a[1].hour > b[1].hour) return 1;
  return 0;
}

export function compareTimeSlots(a: TimeSlotAttributes, b: TimeSlotAttributes) {
  return a.hour === b.hour && a.proUID === b.proUID && a.timePeriod === b.timePeriod && a.unit === b.unit;
}

export function removeUndefined(obj: object) {
  return Object.fromEntries(
    Object.entries(obj).filter(([, value]) => {
      return value !== undefined;
    }),
  );
}
export const getUnitSchedule = (schedule: Schedule, unit: string): Schedule => {
  const newSchedule = structuredClone(schedule);
  const newScheduleEntries = Object.entries(newSchedule)
    .filter(([, day]) => day)
    .map(([dayKey, day]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let newDayEntries = Object.entries(day).map(([timePeriod, timeSlots]) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const newTimeSlotsEntries = Object.entries(timeSlots).filter(([, timeSlot]) => {
          return timeSlot.unit === unit;
        });
        if (newTimeSlotsEntries.length === 0) {
          return undefined;
        }
        const newTimeSlots = Object.fromEntries(newTimeSlotsEntries);
        return [timePeriod, newTimeSlots];
      });
      newDayEntries = newDayEntries.filter((v) => v);
      if (newDayEntries.length === 0) {
        return undefined;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const newDay = Object.fromEntries(newDayEntries);
      return [dayKey, newDay];
    });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.fromEntries(newScheduleEntries.filter((v) => v));
};
