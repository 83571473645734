import style from './TimeSlot.module.css';
import { TimeSlot as TimeSlotInterface } from '../../../Shared/Services/TimefitBackend';
import { ScheduleBtn } from '../ScheduleBtn/ScheduleBtn.tsx';
import { useCurrentUser } from '../../../Shared/Hooks/useCurrentUser/useCurrentUser.ts';

interface HourlyProps {
  timeSlot: TimeSlotInterface;
}

export function TimeSlot({ timeSlot }: HourlyProps) {
  const { record: currentUser } = useCurrentUser();

  return (
    <div className={style.container}>
      <div className={style.infoContainer}>
        <span className={style.hour}>{timeSlot.hour}</span>
        <div>
          <span className={style.staff}>{timeSlot.name}</span>
          <br />
          {currentUser?.admin && <span className={style.client}>{timeSlot.clientName}</span>}
        </div>
      </div>
      <ScheduleBtn timeSlot={timeSlot} />
    </div>
  );
}
