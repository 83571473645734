import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'Campo obrigatório',
  },
  string: {
    email: 'E-mail inválido',
    min: 'Deve ter no mínimo ${min} caracteres',
  },
});
