import { Route } from 'react-router-dom';
import { path } from '../Shared/Services/Path/Path.ts';
import { List } from './Containers/List/List.tsx';
import { Show } from './Containers/Show/Show.tsx';
import { Create } from './Containers/Create/Create.tsx';
import { Edit } from './Containers/Edit/Edit.tsx';

export const routes = (
  <>
    <Route path={path.timeline.list} element={<List />} />
    <Route path={path.timeline.show} element={<Show />} />
    <Route path={path.timeline.create} element={<Create />} />
    <Route path={path.timeline.edit} element={<Edit />} />
  </>
);
