import classNames from 'classnames/bind';
import style from './Time.module.css';
import { TimeSlotAttributes } from '../../../Shared/Services/TimefitBackend';
import { useScheduleState } from '../../Hooks/useScheduleState/useScheduleState.ts';

interface TimeProps {
  timeSlot: TimeSlotAttributes;
}

const cx = classNames.bind(style);

export function Time({ timeSlot }: TimeProps) {
  const { toggleActive, isActive } = useScheduleState();

  return (
    <button className={cx('button', { active: isActive(timeSlot) })} onClick={() => toggleActive(timeSlot)}>
      {timeSlot.hour}
    </button>
  );
}
