import { Field } from '../../../Shared/Components/Field/Field';
import Form from '../../../Shared/Components/Form/Form';
import { Section } from '../../../Shared/Components/Section/Section';
import { SubmitBtn } from '../../../Shared/Components/SubmitBtn/SubmitBtn';
import { PostWithAuthor } from '../../../Shared/Services/TimefitBackend';
import { ImageInput } from '../ImageInput/ImageInput';
import * as Yup from 'yup';

interface EditPostFormProps {
  post: PostWithAuthor;
  loading: boolean;
  onSubmit: (values: EditPostFormValues) => Promise<void>;
}

export interface EditPostFormValues {
  mainImage: string | File;
  title: string;
  content: string;
}

export function EditPostForm({ post, loading, onSubmit }: EditPostFormProps) {
  const validationSchema = Yup.object().shape({
    mainImage: Yup.mixed().required(),
    title: Yup.string().required(),
    content: Yup.string().required(),
  });

  return (
    <Form
      initialValues={{
        mainImage: post.mainImage,
        title: post.title,
        content: post.content,
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <ImageInput name="mainImage" />
      <Section label="Detalhes do post">
        <Field label="Título" name="title" />
        <Field label="Descrição" name="content" as="textarea" rows={5} />
      </Section>
      <SubmitBtn loading={loading}>Atualizar Post</SubmitBtn>
    </Form>
  );
}
