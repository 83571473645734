import React, { useEffect, useState } from 'react';
import { Header } from '../../../Shared/Components/Header/Header';
import { Section } from '../../../Shared/Components/Section/Section';
import { Days } from '../../Components/Days/Days';
import { StaffSchedule } from '../../Components/StaffSchedule/StaffSchedule';
import { Schedule } from '../../../Shared/Services/TimefitBackend';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Loading } from '../../../Shared/Components/Loading/Loading.tsx';
import { path } from '../../../Shared/Services/Path/Path.ts';
import { useSchedule } from '../../Hooks/useSchedule/useSchedule.ts';
import { useSetSchedule } from '../../Hooks/useSetSchedule/useSetSchedule.ts';
import { ScheduleProvider } from '../../Providers/Schedule/Schedule.tsx';
import { useSelectedDayKey } from '../../Hooks/useSelectedDayKey/useSelectedDayKey.ts';

// TODO: Move this to the database
const staffList = [
  {
    name: 'Nara Luana',
    proUID: 'L7t3499vk1XweAa5hPyfLqDhbV52',
  },
  {
    name: 'Altemar Lima',
    proUID: '69nqaFehaDY4vyEIXwhHhFHdxFg2',
  },
  {
    name: 'Natália Lupinacci',
    proUID: 's3Ybc44VlOZfpebf8vMTj2zWrTc2',
  },
  {
    name: 'Hunaldo Rodrigues',
    proUID: '2GuoVCap8FSihZI3SWfvMNuHc892',
  },
  {
    name: 'Axel Gama',
    proUID: 'QrYlMrvv1YeqTyaphu87kSMu8nn2',
  },
  {
    name: 'Terapia Compressiva - Braço',
    proUID: '30639272-f678-462a-8e8a-d313063c469a',
  },
  {
    name: 'Terapia Compressiva - Pernas',
    proUID: 'f90b4292-5d2e-45a3-bc0c-8f523f104f20',
  },
  {
    name: 'CryoSport',
    proUID: '4b96ad4d-0d6d-441f-b968-c98e3c3c37b8',
  },
  {
    name: 'Banheira de Gelo',
    proUID: 'fdedc3f1-b9d1-4f01-9f1e-5b9139d7a08b',
  },
  {
    name: 'Liberação Miofascial',
    proUID: 'a2741469-c203-4b1a-89c2-357d4c3fa033',
  },
  {
    name: 'Full Recovery',
    proUID: '10af3b6a-6ac7-4a0f-9de6-e1c2526f06b8',
  },
  {
    name: 'Breno Ricardo',
    proUID: '1Oud0Ry6aBXsQdfTsPQc2BB0lAI3',
  },
];

export function Edit() {
  const selectedDayKey = useSelectedDayKey();
  const { unit } = useParams();
  const { record: currentSchedule } = useSchedule();
  const setSchedule = useSetSchedule();
  const navigate = useNavigate();

  const [newSchedule, setNewSchedule] = useState<Schedule | undefined>();

  useEffect(() => {
    if (newSchedule === undefined && currentSchedule) {
      setNewSchedule(currentSchedule);
    }
  }, [currentSchedule, newSchedule]);

  const showPath = generatePath(path.scheduling.show, { day: selectedDayKey, unit });

  const handleSave = async () => {
    try {
      await setSchedule.mutateAsync(newSchedule!);
      navigate(showPath);
    } catch (e) {
      throw new Error(
        `Something went wrong when saving the following schedule:\n${JSON.stringify(newSchedule!)}\nOriginal error: ${
          (e as Error).message
        }`,
      );
    }
  };

  const renderSaveAction = () => {
    if (setSchedule.isLoading) {
      return <span>Salvando</span>;
    }

    return (
      <span
        onClick={() => {
          void handleSave();
        }}
      >
        Salvar
      </span>
    );
  };

  if (newSchedule === undefined) {
    return <Loading />;
  }

  return (
    <ScheduleProvider
      newSchedule={newSchedule}
      setNewSchedule={setNewSchedule as React.Dispatch<React.SetStateAction<Schedule>>}
    >
      <Header goBack goBackPath={showPath} title="Editar Horários" action={renderSaveAction()} />
      <Days editing />
      <Section label="Selecione os horários disponíveis">
        {staffList.map((staff) => {
          return <StaffSchedule key={staff.proUID} staff={staff} />;
        })}
      </Section>
    </ScheduleProvider>
  );
}
