import style from './Field.module.css';
import { useField } from 'formik';
import { MaskedInput } from '../MaskedInput/MaskedInput.tsx';
import { InputHTMLAttributes, TextareaHTMLAttributes } from 'react';

interface BaseFieldProps {
  name: string;
  label: string;
}

type TextAreaFieldProps = TextareaHTMLAttributes<HTMLTextAreaElement> & { as?: 'textarea' } & BaseFieldProps;
type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & { as?: 'input' } & BaseFieldProps;

type FieldProps = TextAreaFieldProps | InputFieldProps;

export function Field({ name, label, as = 'input', ...otherProps }: FieldProps) {
  const [field, meta] = useField(name);

  const InputElement = as === 'textarea' ? 'textarea' : MaskedInput;

  return (
    <div className={style.container}>
      <label className={style.label} htmlFor={name}>
        {label}
      </label>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <InputElement className={style.input} id={name} name={name} {...field} {...otherProps} />
      {meta.error && meta.touched ? (
        <div className={style.errorContainer}>
          <span className={style.errorMessage}>{meta.error}</span>
        </div>
      ) : null}
    </div>
  );
}
