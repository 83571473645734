import { LinkProps, Link as LinkReactRouter } from 'react-router-dom';
import style from './Link.module.css';

export function Link({ children, ...otherProps }: React.PropsWithChildren<LinkProps>) {
  return (
    <LinkReactRouter className={style.link} {...otherProps}>
      {children}
    </LinkReactRouter>
  );
}
