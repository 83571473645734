import { useMutation, useQueryClient } from 'react-query';
import { UpdateUserPayload, timefitBackend } from '../../../Shared/Services/TimefitBackend';
import { keys } from '../../../Shared/Services/Query/Keys.ts';

export function useUpdateUser(uid: string) {
  const queryClient = useQueryClient();

  const mutation = (payload: UpdateUserPayload) => {
    return timefitBackend.updateUser(uid, payload);
  };

  return useMutation(mutation, {
    onSuccess: () => {
      void queryClient.invalidateQueries(keys.posts.list());
      void queryClient.invalidateQueries(keys.users.current());
    },
  });
}
