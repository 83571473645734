import Form from '../../../Shared/Components/Form/Form';
import { ImageInput } from '../ImageInput/ImageInput';
import { Field } from '../../../Shared/Components/Field/Field.tsx';
import { SubmitBtn } from '../../../Shared/Components/SubmitBtn/SubmitBtn';
import { Section } from '../../../Shared/Components/Section/Section.tsx';
import * as Yup from 'yup';

export interface CreatePostFormValues {
  mainImage: File | '';
  title: string;
  content: string;
}

interface CreatePostFormProps {
  loading: boolean;
  onSubmit: (values: CreatePostFormValues) => Promise<unknown>;
}

export function CreatePostForm({ loading, onSubmit }: CreatePostFormProps) {
  const validationSchema = Yup.object().shape({
    mainImage: Yup.mixed().required(),
    title: Yup.string().required(),
    content: Yup.string().required(),
  });

  return (
    <Form
      initialValues={{
        mainImage: '',
        title: '',
        content: '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <ImageInput name="mainImage" />
      <Section label="Detalhes do post">
        <Field label="Título" name="title" />
        <Field label="Descrição" name="content" as="textarea" rows={5} />
      </Section>
      <SubmitBtn loading={loading}>Cadastrar Post</SubmitBtn>
    </Form>
  );
}
