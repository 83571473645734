import { Route } from 'react-router-dom';
import { path } from '../Shared/Services/Path/Path.ts';
import { SignIn } from './Containers/SignIn/SignIn.tsx';
import { ForgotPassword } from './Containers/ForgotPassword/ForgotPassword.tsx';
import { Create } from './Containers/Create/Create.tsx';

export const routes = (
  <>
    <Route path={path.authentication.signIn} element={<SignIn />} />
    <Route path={path.authentication.forgotPassword} element={<ForgotPassword />} />
    <Route path={path.authentication.create} element={<Create />} />
  </>
);
