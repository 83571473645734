import { InputHTMLAttributes } from 'react';
import { useField } from 'formik';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import style from './Field.module.css';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { MaskedInput } from '../../../Shared/Components/MaskedInput/MaskedInput.tsx';

interface FieldProps extends InputHTMLAttributes<HTMLInputElement> {
  icon: IconProp;
  name: string;
}

export function Field({ icon, name, ...otherProps }: FieldProps) {
  const [field, meta] = useField(name);

  return (
    <>
      <div className={style.container}>
        <FontAwesomeIcon className={style.icon} icon={icon} />
        <MaskedInput {...field} {...otherProps} className={style.input} id={name} name={name} required />
      </div>
      {meta.error && meta.touched ? (
        <div className={style.errorContainer}>
          <FontAwesomeIcon icon={faTriangleExclamation} className={style.errorIcon} />
          <span>{meta.error}</span>
        </div>
      ) : null}
    </>
  );
}
