import { Field } from '../../../Shared/Components/Field/Field';
import Form from '../../../Shared/Components/Form/Form';
import { Section } from '../../../Shared/Components/Section/Section';
import { SubmitBtn } from '../../../Shared/Components/SubmitBtn/SubmitBtn';
import * as Yup from 'yup';

export interface CreateUserFormValues {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  phone: string;
}

interface CreateUserFormProps {
  loading: boolean;
  onSubmit: (values: CreateUserFormValues) => Promise<unknown>;
}

export function CreateUserForm({ loading, onSubmit }: CreateUserFormProps) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    password: Yup.string().required().min(6),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password')], 'As senhas não são iguais'),
  });

  return (
    <Form
      initialValues={{ email: '', password: '', confirmPassword: '', name: '', phone: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Section>
        <Field name="name" label="Nome" type="text" />
        <Field name="phone" label="Número de telefone" type="tel" />
        <Field name="email" label="E-mail" type="email" />
        <Field name="password" label="Senha" type="password" />
        <Field name="confirmPassword" label="Confirmar Senha" type="password" />
      </Section>
      <SubmitBtn loading={loading}>Criar Timefitter</SubmitBtn>
    </Form>
  );
}
