import { faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import Form from '../../../Shared/Components/Form/Form';
import { Field } from '../Field/Field';
import { SubmitBtn } from '../../../Shared/Components/SubmitBtn/SubmitBtn';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { path } from '../../../Shared/Services/Path/Path';
import { useResetPassword } from '../../Hooks/useResetPassword/useResetPassword';
import { FirebaseError } from 'firebase/app';
import { Link } from '../Link/Link';

export interface ResetPasswordValues {
  email: string;
}

export function ForgotPasswordForm() {
  const resetPassword = useResetPassword();
  const navigate = useNavigate();

  const handleSubmit = async (values: ResetPasswordValues) => {
    try {
      await resetPassword.mutateAsync(values);
      alert('E-mail de recuperação foi enviado com sucesso!');
      navigate(path.authentication.signIn);
    } catch (error) {
      if (error instanceof FirebaseError && error.code === 'auth/invalid-email') {
        alert('E-mail inválido');
      } else if (error instanceof FirebaseError && error.code === 'auth/user-not-found') {
        alert('Usuário não cadastrado');
      } else {
        throw error;
      }
    }
  };

  return (
    <Form initialValues={{ email: '' }} onSubmit={handleSubmit}>
      <Field icon={faEnvelope} name="email" type="email" placeholder="digite seu e-mail" />
      <SubmitBtn loading={resetPassword.isLoading}>
        Recuperar senha <FontAwesomeIcon icon={faPaperPlane} />
      </SubmitBtn>
      <Link to={path.authentication.forgotPassword}>Retornar à página inicial</Link>
    </Form>
  );
}
